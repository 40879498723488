import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    liveScores: {
        "2": {
          // Match Info
          matchId: "2",
          type: "Indian Premier League",
          round: "Match 7",
          play_status: "in_play", // "in_play", "innings_break", etc.
          starting_at: Math.floor(Date.now() / 1000), // Current timestamp
          
          // Teams
          team_a: {
            name: "Mumbai Indians",
            code: "MI",
            teamId: "ipl1",
          },
          team_b: {
            name: "Chennai Super Kings",
            code: "CSK",
            teamId: "ipl2",
          },
          
          // Match Progress
          currentBattingTeam: { 
            teamId: "ipl1", 
            name: "Mumbai Indians" 
          },
          requiredScore: "CSK need 142 runs in 84 balls",
          inning: 2, // 1st or 2nd inning
          
          // Scorecard
          runs: [
            { 
              team: { teamId: "ipl1" }, // MI Batting
              runs: 165, 
              wickets: 4, 
              overs: 20 
            },
            { 
              team: { teamId: "ipl2" }, // CSK Batting
              runs: 78, 
              wickets: 2, 
              overs: 11 
            },
          ],
          
          // Batting Stats (Current Inning)
          batting: [
            {
              name: "Rohit Sharma",
              player: "Striker", // "Striker" or "NonStriker"
              balls: 42,
              runs: 68,
              ones: 18,
              twos: 5,
              threes: 1,
              fours: 7,
              sixes: 2,
              strike_rate: 161.9,
              isOut: false,
            },
            {
              name: "Ishan Kishan",
              player: "NonStriker",
              balls: 36,
              runs: 45,
              ones: 12,
              twos: 4,
              threes: 0,
              fours: 5,
              sixes: 1,
              strike_rate: 125.0,
              isOut: false,
            },
          ],
          
          // Bowling Stats (Current Over)
          bowling: [
            { 
              currentBall: "11.1",
              runs: 1,
              isWicket: false,
              four: false,
              six: false,
              wide: false,
              noBall: false,
              bye: false,
              legBye: false,
              bowler: "Deepak Chahar",
            },
            { 
              currentBall: "11.2",
              runs: 4,
              four: true,
            },
            { 
              currentBall: "11.3",
              runs: 0,
              isWicket: true,
              wicketType: "caught", // "bowled", "lbw", etc.
              batsman: "Suryakumar Yadav",
            },
            { 
              currentBall: "11.4",
              runs: 1,
              wide: true,
            },
            { 
              currentBall: "11.5",
              runs: 6,
              six: true,
            },
            { 
              currentBall: "11.6",
              runs: 2,
            },
          ],
          
          // Fall of Wickets (Optional)
          fallOfWickets: [
            { teamId: "ipl1", batsman: "Quinton de Kock", runs: 28, overs: 5.3 },
            { teamId: "ipl1", batsman: "Suryakumar Yadav", runs: 112, overs: 15.2 },
          ],
          
          // Toss Info (Optional)
          tossResult: "CSK won the toss and chose to bowl",
        },
      },
    closeOrOpenBet: {}
};

const webSocketSlice = createSlice({
    name: "webSocket",
    initialState,
    reducers: {
        setLiveScores: (state, action) => {
            const newScores = action.payload;
            state.liveScores = {
                ...state.liveScores,
                ...newScores,
            };
            localStorage.setItem("liveScores", JSON.stringify(state.liveScores));
        },
        setCloseOrOpenBet: (state, action) => {
            state.closeOrOpenBet = action.payload;
        }
    },
});

export const {
    setLiveScores,
    setCloseOrOpenBet
} = webSocketSlice.actions;

export default webSocketSlice.reducer;
