import axiosInstance from "../axiosInstance";

export const fetchUserBalance = async (userId) => {
    try {
        const response = await axiosInstance.get(`api/user/fetchUserBalance/${userId}`);
        const balance = response.data.userBalance;
        localStorage.setItem('userBalance', JSON.stringify(balance));
        return balance;
    } catch (error) {
        console.error(error);
    }
}

export const formatMoney = (value) => {
    if (value >= 10000000) {
      return (value / 10000000).toFixed(2) + " Cr";
  } else if (value >= 100000) {
      return (value / 100000).toFixed(2) + " L"; 
  } else if (value >= 1000) {
      return (value / 1000).toFixed(2) + " K"; 
  } else {
      return value?.toString(); 
  }
  }