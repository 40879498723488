import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import LiveMatch from "./components/LiveMatch";
import UpcomingMatch from "./components/UpcomingMatch";
import {
  // getCricketDetails,
  getCricketInfo,
} from "./redux/features/cricketSlice";
import { fetchUserBalance } from "../utils/userBalance";

const staticMatches = [
  {
    matchId: 1,
    starting_at: new Date().getTime() + 3600000, // 1 hour from now
    status: 'live',
    play_status: 'live',
    league: 'Premier League',
    association: 'ICC',
    type: 'T20',
    team_a: {
      teamId: 'A1',
      name: 'Team Alpha',
      code: 'ALP',
    },
    team_b: {
      teamId: 'B1',
      name: 'Team Bravo',
      code: 'BRV',
    },
    round: 'Semi Final',
    title: 'Alpha vs Bravo',
    name: 'Championship Match',
    isLive: true,
    isOpenForBet: false,
    isFetchingLiveScore: false,
    checkedbyAdmin: true,
    tossResult: null,
  },
  {
    matchId: 2,
    starting_at: new Date().getTime() + 7200000, // 2 hours from now
    status: 'live',
    play_status: 'Live',
    league: 'Champions League',
    association: 'BCCI',
    type: 'ODI',
    team_a: {
      teamId: 'C1',
      name: 'Team Charlie',
      code: 'CHR',
    },
    team_b: {
      teamId: 'D1',
      name: 'Team Delta',
      code: 'DLT',
    },
    round: 'Quarter Final',
    title: 'Charlie vs Delta',
    name: 'League Battle',
    isLive: true,
    isOpenForBet: true,
    isFetchingLiveScore: false,
    checkedbyAdmin: true,
    tossResult: 'Team Charlie won the toss',
  },
  {
    matchId: 3,
    starting_at: new Date().getTime() + 10800000, // 3 hours from now
    status: 'upcoming',
    play_status: 'scheduled',
    league: 'National Cup',
    association: 'ECB',
    type: 'Test',
    team_a: {
      teamId: 'E1',
      name: 'Team Echo',
      code: 'ECO',
    },
    team_b: {
      teamId: 'F1',
      name: 'Team Foxtrot',
      code: 'FOX',
    },
    round: 'Final',
    title: 'Echo vs Foxtrot',
    name: 'National Cup Final',
    isLive: false,
    isOpenForBet: false,
    isFetchingLiveScore: false,
    checkedbyAdmin: true,
    tossResult: null,
  },
  {
    matchId: 4,
    starting_at: new Date().getTime() + 14400000, // 4 hours from now
    status: 'upcoming',
    play_status: 'scheduled',
    league: 'Regional League',
    association: 'CSA',
    type: 'T20',
    team_a: {
      teamId: 'G1',
      name: 'Team Golf',
      code: 'GLF',
    },
    team_b: {
      teamId: 'H1',
      name: 'Team Hotel',
      code: 'HOT',
    },
    round: 'Group Stage',
    title: 'Golf vs Hotel',
    name: 'Regional Match 1',
    isLive: false,
    isOpenForBet: true,
    isFetchingLiveScore: false,
    checkedbyAdmin: true,
    tossResult: null,
  },
];

const liveMatches = staticMatches.filter((match) => match.status === 'live');
const upcomingMatches = staticMatches.filter((match) => match.status === 'upcoming');

const cricketDetails = {
  liveMatches,
  upcomingMatches,
};
const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const userId = JSON.parse(localStorage.getItem("userId"));
  const token = JSON.parse(localStorage.getItem("accessToken"));
  const userBalance = JSON.parse(localStorage.getItem("userBalance")) || 0;
  const return_url = JSON.parse(localStorage.getItem("return_url"));
  const [cricketDetail, setCricketDetail] = useState(cricketDetails);
  const { isLoading, cricketInfo } = useSelector(
    (state) => state.cricket
  );


  
  
  const handleFetchBalance = async () => {
    setLoading(true);
    try {
      const balance = await fetchUserBalance(userId);
      return balance;
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!userId && !token) {
      navigate("/");
      return;
    }
    if (userId) {
      fetchUserBalance(userId);
    }
    // if (!cricketDetail) {
    //   dispatch(getCricketDetails());
    // }
    if (!cricketInfo) {
      dispatch(getCricketInfo());
    }
    if (!token) {
      navigate("/");
    }
    setCricketDetail(cricketDetails);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(
        (prevIndex) => (prevIndex + 1) % cricketInfo?.bannerLogo.length
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [cricketInfo?.bannerLogo]);

  function calculateTimeLeft(unixTimestamp) {
    const currentDate = moment();
    const matchDate = moment.unix(unixTimestamp);

    if (matchDate.isAfter(currentDate, "day")) {
      return matchDate.format("MMM D, YYYY");
    }

    const timeDifference = matchDate.diff(currentDate);

    if (timeDifference <= 0) {
      return "LIVE";
    }

    const hoursLeft = moment.duration(timeDifference).hours();
    const minutesLeft = moment.duration(timeDifference).minutes();
    const secondsLeft = moment.duration(timeDifference).seconds();

    return `${hoursLeft}h ${minutesLeft}m ${secondsLeft}s`;
  }

  return (
    <main className="shadow">
      <header className="header position-sticky px-4 d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center ">
          <a
            href={`${return_url ? return_url : "not-found"}`}
            className="bi bi-arrow-left-circle me-2 fs-4 text-white"
            type="button"
            aria-label="Go back to the previous page"
          />
          <h6 className="m-0 fw-bolder py-3">Leave Page</h6>
        </div>
        <div className="header-aside bg-white border rounded-pill text-black p-1 d-flex justify-content-between align-items-center">
          <img src="/assets/balRupees.svg" alt="rupee" />
          <div>
            <h6 className="m-0 fw-bold flex-fill px-2 pt-1">
              {Number(userBalance.toFixed()).toLocaleString("en-IN")}
            </h6>
          </div>
          <i
            className={`fw-bolder bi bi-arrow-clockwise pe-1 fs-5 pt-1 text-success ${
              loading ? "rotate" : ""
            }`}
            onClick={() => handleFetchBalance(userId)}
            type="button"
          ></i>
        </div>
      </header>
      <div className="mx-smm-2 mx-smm-3 pt-3 mb-5">
        {cricketInfo && cricketInfo.bannerLogo.length > 0 ? (
          <>
            <div className="image-container">
              {cricketInfo.bannerLogo.map((url, index) => (
                <img
                  key={index}
                  src={url}
                  alt="Slider"
                  className={`slider__item rounded ${
                    index === currentIndex ? "slider__item-visible" : ""
                  }`}
                />
              ))}
            </div>
            <div className="d-flex justify-content-center align-items-center my-2">
              {cricketInfo.bannerLogo.map((_, index) => (
                <span
                  key={index}
                  type="button"
                  className={`rounded mx-1 ${
                    currentIndex === index ? "active" : "dot"
                  }`}
                  onClick={() => setCurrentIndex(index)}
                ></span>
              ))}
            </div>
            <div className="notification text-white mt-3 d-flex justify-content-between align-items-center border rounded-pill">
              <img src="/assets/sound.svg" alt="sound" className="px-1 m-1" />
              <p
                className="m-0 m-1 fs-12 lh-sm fw-light"
                style={{ fontSize: "11px" }}
              >
                {cricketInfo ? cricketInfo.notificationText : ""}
              </p>
              <a
                href={cricketInfo ? cricketInfo.notificationLink : ""}
                target="blank"
                type="button"
                className="text-decoration-none redShadowBg text-white d-flex p-2 px-3 justify-content-between align-items-center rounded-pill"
                style={{ fontSize: "14px" }}
              >
                <img src="/assets/fire.svg" alt="fire" className="pe-1" />
                Detail
              </a>
            </div>
          </>
        ) : (
          <div className="image-container">
            <img
              src="/assets/liveBannerNew.svg"
              alt="Slider"
              className="slider__item rounded slider__item-visible object-fit-cover"
            />
          </div>
        )}

        <div className="mt-3 border-top border-bottom pt-2">
          <h6 className="fw-bolder">Live Matches</h6>
          { cricketDetail && cricketDetail.liveMatches.length > 0 ? (
            [...cricketDetail.liveMatches]
              .sort((a, b) => a.starting_at - b.starting_at)
              .map((match) => (
                <div key={match.matchId}>
                  <LiveMatch
                    match={match}
                    calculateTimeLeft={calculateTimeLeft}
                  />
                </div>
              ))
          ) : (
            <div className="bg-backgroundImg h-15 d-flex justify-content-center align-items-center border rounded">
              <h6 className="text-danger fw-bold m-0 text-center">
                There is no Live Matches
              </h6>
            </div>
          )}
        </div>
        <div className="mt-3">
          <h6 className="my-3 fw-bolder">Upcoming Matches</h6>
          <div className="mb-4">
            {  cricketDetail &&
              cricketDetail.upcomingMatches &&
              cricketDetail.upcomingMatches.length > 0 ? (
              [...cricketDetail.upcomingMatches]
                .sort((a, b) => a.starting_at - b.starting_at)
                .map((match) => (
                  <div key={match.matchId}>
                    <UpcomingMatch
                      match={match}
                      calculateTimeLeft={calculateTimeLeft}
                    />
                  </div>
                ))
            ) : (
              <div className="bg-backgroundImg h-15 d-flex justify-content-center align-items-center border rounded">
                <h6 className="text-danger fw-bold m-0 text-center">
                  There are no upcoming Matches
                </h6>
              </div>
            )}
          </div>
        </div>
      </div>
      <footer className="footer d-flex justify-content-center">
        <button
          className="group footerButton rounded-pill my-2 px-4 text-white fw-bold"
          onClick={() => navigate("/my-matches")}
        >
          <img
            src="/assets/MyMatchesIcon.svg"
            alt="ICON"
            className="w-25 h-50"
          />
          <br />
          My Matches
        </button>
      </footer>
    </main>
  );
};

export default Home;
