import React from "react";
import { Modal } from "react-bootstrap";

const BetSuccessModel = ({ show, setSelectBetModel, selectedAmount, placedBet, setSelectedAmount, question, teamA, teamB }) => {

    return (
        <Modal
            show={show}
            onHide={() => setSelectBetModel(false)}
            centered
            backdrop="static"
        >
            <Modal.Header className="py-2 d-flex justify-content-between modelHeader text-white">
                <h3 className="modal-title flex-fill text-center fw-bolder">
                    Select Betting Amount
                </h3>
                <i
                    onClick={() => setSelectBetModel(false)}
                    className="bi bi-x-circle fs-4 fw-bold"
                    type="button"
                ></i>
            </Modal.Header>

            <Modal.Body className="p-0 pt-3 text-center mb-3">
                <div className="modal-body pt-0">
                    <div className="row p-0">
                        {[
                            100, 150, 200, 300, 400, 500, 600, 800, 1000, 2000, 5000, 10000,
                        ].map((amount, index) => (
                            <div key={index} className="col-4 text-center my-3">
                                <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setSelectedAmount(amount)}
                                    className={`${amount === selectedAmount ? "selected-amount" : "greenShadowBg"
                                        } text-white rounded-pill fw-bold py-1`}
                                >
                                    &#x20B9;{amount.toLocaleString("en-IN")}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="border border-danger rounded my-2">
                        <h5 className="modelHeader p-2 text-center w-100 fw-bolder rounded-top text-white">
                            Winning Amount
                        </h5>
                        <div className="d-flex justify-content-between">
                            <div className="w-50 mx-2">
                                <div className="fs-12 fw-bolder">
                                    <i className="bi bi-database-fill text-success fs-5"></i>
                                    Betting Amount
                                </div>
                            </div>
                            <div className="w-50 mx-2">
                                <div className="fs-12 fw-bolder">
                                    <i className="bi bi-database-fill text-success fs-5"></i>
                                    Winning Amount Upto
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <div className="w-50 m-2 border border-danger rounded d-flex align-items-center fw-bold">
                                <span className="modelHeader text-white p-2 rounded-start">
                                    &#x20B9;
                                </span>
                                <span className="flex-fill text-center">
                                    {selectedAmount.toLocaleString("en-IN")}
                                </span>
                            </div>
                            <div className="w-50 m-2 border border-danger rounded d-flex align-items-center fw-bold">
                                <span className="modelHeader text-white p-2 rounded-start">
                                    &#x20B9;
                                </span>
                                <div className="flex-fill text-start ps-2 border-0">
                                    <span>
                                        <p className="m-0">
                                            <span>{question.questionId < 4 ? teamA?.code : "Yes"} : </span>
                                            <span className="ps-1">
                                                {" "}
                                                {`${question.worst || 101}-${question.best || 170}`}
                                            </span>
                                        </p>
                                        <p className="m-0">
                                            <span>{question.questionId < 4 ? teamB?.code : "No"} : </span>
                                            <span className="ps-1">
                                                {" "}
                                                {`${question.worstTeamB || 170}-${question.bestTeamB || 200}`}
                                            </span>
                                        </p>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt-3">
                        <button
                            className={`text-white fs-5 fw-bold redShadowBg border rounded-pill py-1 px-4 `}
                            onClick={placedBet}
                        >
                            Confirm Bet
                        </button>
                    </div>
                </div>
            </Modal.Body>

        </Modal>
    );
};

export default BetSuccessModel;
