import React, { createContext, useContext, useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  setTotalBets,
  setMatchTotalBets,
  updateBetQuestions,
  updateUserPendingQuestions,
  setUserBetStatus,
} from "./pages/redux/features/cricketSlice";
import {
  setCloseOrOpenBet,
  setLiveScores,
} from "./pages/redux/features/webSocketSlice";
import { fetchUserBalance } from "./utils/userBalance";

const WebSocketContext = createContext(null);

export const useWebSocket = () => {
  return useContext(WebSocketContext);
};

export const WebSocketProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [webSocket, setWebSocket] = useState(null);
  const [showResultModel, setResultShowModel] = useState(false);
  const [result, setResult] = useState(null);
  const [betConfirmedModel, setBetConfirmedModel] = useState(false);
  const [betErrorModel, setBetErrorModel] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");


  const userId = JSON.parse(localStorage.getItem("userId"));
  const token = JSON.parse(localStorage.getItem("accessToken"));

  const sendPing = (socket) => {
    if (socket.readyState === WebSocket.OPEN) {
      socket.send(
        JSON.stringify({
          gameType: "cricket",
          type: "PING",
        })
      );
    }
  };
  const handleMessage = (message) => {
    switch (message.type) {
      case "LIVE_SCORES":
        const newScores = { [message.matchId]: message.liveScore };
        dispatch(setLiveScores(newScores));
        break;

      case "NEW_QUESTION":
      case "RESET_QUESTIONS":
      case "QUESTION_RESULT_DECLARED":
        dispatch(updateBetQuestions(message));
        break;

      case "USER_LOCKED_QUESTION":
        if (message.userId === userId) {
          dispatch(updateUserPendingQuestions(message));
        }
        break;
      case "TOTAL_FAKE_QUESTION_BETS":
        dispatch(setTotalBets(message));

        break;
      case "TOTAL_FAKE_MATCH_BETS":
        dispatch(setMatchTotalBets(message));

        break;
      case "BET_STATUS":
        dispatch(setCloseOrOpenBet(message));
        const notificationText = message.status
          ? "You can place bet Now"
          : "You can not place bet Now";
        console.log(notificationText);
        break;

      case "USER_BET_STATUS":
        if (message.Bet.userId === userId) {
          dispatch(setUserBetStatus(message.Bet));
          if (message.Bet.result !== "pending") {
            setResult(message.Bet);
            setResultShowModel(true);
          }
        }
        break;

      case "BET_SUCCESS":
        setBetConfirmedModel(true);
        fetchUserBalance(userId);
        break;

      case "ERROR":
        setBetErrorModel(true);
        setErrorMessage(message?.message);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (!token) return;

    const socket = new WebSocket(`${process.env.REACT_APP_WS}`, token);

    socket.onopen = () => {
      console.log("WebSocket connection established successfully");
      const subscribeMessage = {
        gameType: "cricket",
        type: "SUBSCRIBE",
      };
      socket.send(JSON.stringify(subscribeMessage));

      socket.onpong = () => {
        console.log("Pong received, connection is alive");
      };
    };

    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      handleMessage(message);
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };
    socket.onclose = () => {
      console.log("WebSocket connection Disconnected");
    };

    setWebSocket(socket);
    const pingInterval = setInterval(() => sendPing(socket), 30000);
    return () => {

      const unSubscribeMessage = {
        gameType: "cricket",
        type: "UNSUBSCRIBE",
      };

      socket.send(JSON.stringify(unSubscribeMessage));
      clearInterval(pingInterval);
      socket.close();

    };
  }, [token]);

  return (
    <WebSocketContext.Provider
      value={{
        webSocket,
        result,
        showResultModel, setResultShowModel,
        betConfirmedModel, setBetConfirmedModel,
        betErrorModel, setBetErrorModel,
        errorMessage, setErrorMessage,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};
